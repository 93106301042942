<template>
  <section>
    <b-row>
      <b-col lg="12">
        <div class=" ">
          <b-card>
            <b-row>
              <!-- User Info: Left col -->
              <b-col lg="6" class="d-flex justify-content-between flex-column">
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                  <b-avatar
                    :src="userData.avatar"
                    :text="avatarText(userData.name)"
                    size="104px"
                    rounded
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h4 class="mb-0">
                        {{ userData.nom }} {{ userData.prenoms }}
                      </h4>
                      <span class="card-text">{{ userData.email }}</span>
                    </div>
                    <div class="d-flex flex-wrap">
                      <b-button :disabled="state.loading === true ? true : false" variant="primary" @click="update" v-b-modal.modal-update-user>
                        
                        
                        <span v-if="state.loading === false"><feather-icon icon="Edit3Icon"  class="" /> Modifier</span>
               <b-spinner v-if="state.loading === true" label="Spinning"></b-spinner>
                      </b-button>
                      <b-button variant="outline-danger" class="ml-1">
                        <feather-icon icon="TrashIcon" class="" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-col>

              <!-- Right Col: Table -->
              <b-col  class="pt-3 pb-2" lg="12">
                <table>
                  <div>
                    <!-- <h3>Coordonnées</h3>
                    <hr> -->
                    <table
                      class="mt-2 mt-xl-0 w-100 d-flex flex-column"
                      style="gap:8px"
                    >
                      <tr class=" d-flex" style="gap:4px">
                        <th class="pb-50">
                          <feather-icon icon="MailIcon" class="mr-75" />
                          <span class="font-weight-bold">Email : </span>
                        </th>
                        <td class="pb-50 text-capitalize">
                          {{ userData.email }}
                        </td>
                      </tr>

                      <tr class=" d-flex" style="gap:4px">
                        <th class="pb-50">
                          <feather-icon icon="HomeIcon" class="mr-75" />
                          <span class="font-weight-bold">Adresse : </span>
                        </th>
                        <td class="pb-50">
                          {{ userData.localisation === undefined ? '' :  userData.localisation.formatted_address }}
                        </td>
                      </tr>

                      <tr class=" d-flex" style="gap:4px">
                        <th class="pb-50">
                          <feather-icon icon="UserIcon" class="mr-75" />
                          <span class="font-weight-bold">Code : </span>
                        </th>
                        <td class="pb-50">
                          {{ userData.code }}
                        </td>
                      </tr>

                      <tr class=" d-flex" style="gap:4px">
                        <th class="pb-50">
                          <feather-icon icon="PhoneIcon" class="mr-75" />
                          <span class="font-weight-bold">Contact : </span>
                        </th>
                        <td class="pb-50">
                          {{ userData.indicateur }} {{ userData.contact }}
                        </td>
                      </tr>

                      <tr class=" d-flex" style="gap:4px">
                        <th class="pb-50">
                          <feather-icon icon="TypeIcon" class="mr-75" />
                          <span class="font-weight-bold">Type : </span>
                        </th>
                        <td class="pb-50 text-capitalize">
                          {{ userData.status_user }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </table>
              </b-col>
            </b-row>
          </b-card>
          <!-- </div> -->

          <!-- :::::::: -->

          <!-- Debut tabs -->

          <!-- <b-card>
          <client-more-detail/>      
        </b-card> -->

          <b-card no-body>
            <b-tabs 
              pills
              card
              class="flex justify-end justify-content-md-between"
            >
              <!-- content-class="mt-2" justified  -->
              <!-- tabs de ventes à relevé -->
              <b-tab no-body>
                <template #title class="flex">
                  <feather-icon
                    :id="`invoice-row-1-send-icon`"
                    icon="LayersIcon"
                    class="cursor-pointer"
                    size="16"
                  />
                  <div class="d-flex flex-row">
                    <span class="d-none d-md-flex">Factures </span>
                    <span style="margin-left: 4px">({{ listFacClient.length }})</span>
                  </div>
                </template>

                <div class="mx-2">
                  <!-- Table Top -->
                  <b-row>
                    <!-- Per Page -->
                    <b-col
                      cols="12"
                      md="6"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                      <label>Entrées</label>
                      <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                      />
                      <!-- <b-button
                            variant="primary"
                            :to="{ name: 'FactureAdd'}"
                          >
                            Créer une nouvelle facture
                          </b-button> -->
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6" class="">
                      <div
                        class="d-flex align-items-center justify-content-end"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="SearchIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            v-model="filtre_facture"
                            class="d-inline-block mr-1"
                            placeholder="Rechercher par : nom d'article, type, prix valeur..."
                          />
                        </b-input-group>
                      </div>
                    </b-col>
                  </b-row>
                </div>

                <!-- Le tableau affichant les factures -->
                <b-table
                  hover
                  responsive
                  primary-key="id"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="tableColumns"
                  :filter="filtre_facture"
                  :items="inData"
                  show-empty
                  empty-text="Patienter svp"
                  class="bg-white mt-2"
                >
                  <template #head(invoiceStatus)>
                    <feather-icon icon="TrendingUpIcon" class="mx-auto" />
                  </template>

                  <!-- Column: Montant total -->
                  <template #cell(code)="data">
                    
                    <div class="py-50">
                      <span class="text-nowrap  ">
                      {{ data.item.code }}
                    </span>
                    </div>
                  </template>

                  <!-- Column: Montant total -->
                  <template #cell(total_ttc)="data">
                    <span class="text-nowrap text-info">
                      {{ data.item.total_ttc }}
                    </span>
                  </template>

                  <!-- Column: Payé -->
                  <template #cell(state)="data">
                    <span class="text-nowrap text-success">
                      {{ data.item.id }}
                    </span>
                  </template>

                  <!-- Column: Impayé -->
                  <template #cell(impaye)="data">
                    <span class="text-nowrap text-danger font-weight-bold">
                      {{ data.item.impaye }}
                    </span>
                  </template>

                  <!-- Column: Issued Date -->
                  <template #cell(date_echeance)="data">
                    <span class="text-nowrap">
                      {{ format_date(data.value) }}
                    </span>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <div class="text-nowrap py-1">
                      <feather-icon
                        :id="`invoice-row-${data.item.id}-send-icon`"
                        icon="SendIcon"
                        class="cursor-pointer"
                        size="16"
                        @click="data.item;"
                      />
                      <b-tooltip
                        title="Envoyer la facture"
                        class="cursor-pointer"
                        :target="`invoice-row-${data.item.id}-send-icon`"
                      />

                      <feather-icon
                        :id="`invoice-row-${data.item.id}-preview-icon`"
                        icon="EyeIcon"
                        size="16"
                        class="mx-1 cursor-pointer"
                        @click="data.item.id;"
                      />
                      <b-tooltip
                        title="Prévisualiser la facture"
                        :target="`invoice-row-${data.item.id}-preview-icon`"
                      />

                      <!-- Dropdown -->
                      <b-dropdown
                        variant="link"
                        toggle-class="p-0"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <!-- <b-dropdown-item @click="editFac(data.item.id)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Modifier</span>
                          </b-dropdown-item> -->
                        <b-dropdown-item>
                          <feather-icon icon="ShoppingBagIcon" />
                          <span class="align-middle ml-50">Régler</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="data.item.id;">
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">Supprimer</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>

                <!-- Parfination -->
                <div class="mx-2 mb-2">
                  <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted"></span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="inData.length"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </b-col>
                  </b-row>
                </div>
              </b-tab>

              <!--  -->
              <b-tab no-body>
                <template #title class="flex">
                  <feather-icon
                    :id="`invoice-row-1-send-icon`"
                    icon="LayersIcon"
                    class="cursor-pointer"
                    size="16"
                  />
                  <div class="d-flex flex-row">
                    <span class="d-none d-md-flex">Devis </span>
                    <span style="margin-left: 4px">({{ devisData.length }})</span>
                  </div>
                </template>

                

                <div class="mx-2">
                  <!-- Table Top -->
                  <b-row>
                    <!-- Per Page -->
                    <b-col
                      cols="12"
                      md="6"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                      <label>Entrées</label>
                      <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                      />
                      <!-- <b-button
                            variant="primary"
                            :to="{ name: 'FactureAdd'}"
                          >
                            Créer une nouvelle facture
                          </b-button> -->
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6" class="">
                      <div
                        class="d-flex align-items-center justify-content-end"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="SearchIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            v-model="filtre_facture"
                            class="d-inline-block mr-1"
                            placeholder="Rechercher par : nom d'article, type, prix valeur..."
                          />
                        </b-input-group>
                      </div>
                    </b-col>
                  </b-row>
                </div>

                <!-- Le tableau affichant les factures -->
                <b-table
                  hover
                  responsive
                  primary-key="id"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="tableColumnsDevis"
                  :filter="filtre_devis"
                  :items="devisData"
                  show-empty
                  empty-text="Patienter svp"
                  class="bg-white mt-2"
                >
                 
                </b-table>

                <!-- Parfination -->
                <div class="mx-2 mb-2">
                  <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted"></span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                      <b-pagination
                        v-model="currentPageDevis"
                        :total-rows="devisData.length"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </b-col>
                  </b-row>
                </div>
                    
              </b-tab>

              <!-- <b-tab>
                <template #title>
                  <feather-icon icon="MessageCircleIcon" />
                  <span>Commentaires()</span>
                </template>
                <div>
                  <b-form-textarea
                    id="textarea-default"
                    placeholder="Commentaire"
                    rows="3"
                  />
                </div>

                <div class=" mt-1">
                  <b-button
                    v-b-toggle.sidebar-invoice-add-new-customers
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    Envoyé
                  </b-button>
                </div>
              </b-tab> -->

              

              <b-tab no-body>
                <template #title class="flex">
                  <feather-icon
                    :id="`invoice-row-1-send-icon`"
                    icon="CardIcon"
                    class="cursor-pointer"
                    size="16"
                  />
                  <div class="d-flex flex-row">
                    <span class="d-none d-md-flex">Versements </span>
                    <span style="margin-left: 4px">({{ versementsData.length }})</span>
                  </div>
                </template>

                <!-- Afficher les details des versements de la facture -->
                <b-table
                  hover
                  responsive
                  primary-key="id"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="tableColumnsVersements"
                  :items="versementsData"
                  show-empty
                  empty-text="Patienter svp"
                  class="bg-white"
                 >
                </b-table>
              </b-tab>


              <b-tab no-body>
                <template #title class="flex">
                  <feather-icon
                    :id="`invoice-row-1-send-icon`"
                    icon="ArchiveIcon"
                    class="cursor-pointer"
                    size="16"
                  />
                  <div class="d-flex flex-row">
                    <span class="d-none d-md-flex">Historiques </span>
                    <span style="margin-left: 4px">({{ archiveData.length }})</span>
                  </div>
                </template>

                <!-- Afficher les historiques de l'utilisateur -->
                <b-table
                  hover
                  responsive
                  primary-key="id"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="tableColumnsArchive"
                  :items="archiveData"
                  show-empty
                  empty-text="Patienter svp"
                  class="bg-white mb-4"
                 
                >
                
                </b-table>
              </b-tab>
            </b-tabs>
          </b-card>

          <!-- End tabs -->

          <!-- :::::::::::: -->

          <!-- Modal pour mettre à jour un client    -->
          <b-modal
            id="modal-update-user"
            cancel-variant="outline-secondary"
            ok-title="Enregistre"
            ok-only
            centered
            title="Modifier mon client"
            @ok="updateClient(userData.id)"
          >
            <b-form class="auth-register-form mt-2">
              <!-- nom -->
              <b-form-group label-for="register-nom">
                <label for="">Nom <span class=" text-danger h6">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="nom"
                  rules="required"
                >
                  <b-form-input
                    id="register-nom"
                    v-model="user.nom"
                    name="register-nom"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Bazaroph"
                  />
                </validation-provider>
              </b-form-group>

              <!-- prenom -->
              <b-form-group label-for="register-prenom">
                <label for=""
                  >Prénom <span class=" text-danger h6">*</span></label
                >
                <validation-provider
                  #default="{ errors }"
                  name="prenom"
                  rules="required"
                >
                  <b-form-input
                    id="register-prenom"
                    v-model="user.prenoms"
                    name="register-prenom"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe"
                  />
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label-for="register-email">
                <label for=""
                  >Email <span class=" text-danger h6">*</span></label
                >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="register-email"
                    v-model="user.email"
                    type="email"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                </validation-provider>
              </b-form-group>

              <!-- contact -->
              <b-form-group label-for="register-contact">
                <label for=""
                  >Contact <span class=" text-danger h6">*</span></label
                >
                <validation-provider
                  #default="{ errors }"
                  name="contact"
                  rules="required"
                >
                  <vue-tel-input
                    id="register-contact"
                    @country-changed="changer"
                    v-model="user.contact"
                    name="register-contact"
                    :state="errors.length > 0 ? false : null"
                    placeholder="000-000-000-000"
                  />
                </validation-provider>
              </b-form-group>


           

              <!-- Localisation -->
              <b-form-group label-for="entreprise-localisation">
                <label for=""
                  >Localisation <span class=" text-danger h6">*</span></label
                >
              
                   <b-form-input
                  id="autocomplete_search"
                  @input="initialize"
                  name="autocomplete_search"
                  type="text"
                  v-model="user.localisation"
                  placeholder="Abidjan, Angré RCI 0089 BP 00225"
               />
               <input type="hidden" name="lat" />
               <input type="hidden" name="long" />
              </b-form-group>

              <b-form-group>
                <label for=""
                  >Status <span class=" text-danger h6">*</span></label
                >
                <v-select
                  v-model="user.type_client"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="clientUpOption"
                />
              </b-form-group>
            </b-form>
          </b-modal>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BModal,
  BFormInput,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BFormFile,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import URL from '@/views/pages/request';
import axios from 'axios';
import moment from 'moment';
import { VueTelInput } from 'vue-tel-input';
import qDeviseUtils from '@/utils/qDeviseUtils';
import { qDecrypted } from '@/services/qeCrypt';
import { ref } from "@vue/composition-api"
import { toast_update } from '@/utils/qToast';
import { onMounted, reactive} from "@vue/composition-api"
// import ClientMoreDetail from '@/components/clients/Preview/clientMoreDetail.vue';
// import QInvoiceMoreDetails from "@/components/factures/Preview/qInvoiceMoreDetails.vue";

// import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    moment,
    BFormFile,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    VueTelInput,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    BForm,
    BImg,
    BLink,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,

    // factureTotal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: '',

      user: {
        nom: '',
        prenoms: '',
        email: '',
        contact: '',
        localisation: '',
        type_client: '',
      },
      status_id: '',
      indicateur: '',

      filtre_facture: '',
      filtre_devis: '',
      listFacClient: '',
      versFacClient: [],

      versements: [],

      factureList: [],

      tableColumns: [
        { key: 'code', label: 'Code facture', sortable: true },
        { key: 'total_ttc', sortable: true },
        { key: 'paye', label: 'Payé', sortable: true },
        { key: 'impaye', label: 'Impayé', sortable: true },
        { key: 'date_echeance', sortable: true },
        // { key: 'actions' },
      ],
       tableColumnsDevis: [
        { key: 'code', label: 'Code Devis', sortable: true },
        { key: 'total_ttc', sortable: true },
        { key: 'created', label:'Date d\'Ajout', sortable: true },
        // { key: 'actions' },
      ],
      tableColumnsVersements: [
        { key: 'code', label: 'Code Versement', sortable: true },
        { key: 'montant', sortable: true },
        { key: 'created', label:'Date d\'Ajout', sortable: true },
        // { key: 'actions' },
      ],
      tableColumnsArchive: [
        { key: 'causer_type', label: 'types', sortable: true },
        { key: 'description', sortable: true },
        { key: 'created_at', label:'Date d\'Ajout', sortable: true },
        // { key: 'actions' },
      ],
      perPageOptions: [30, 50, 100, 200, 500],

      clientUpOption: [{ title: 'Particulier' }, { title: 'Entreprise' }],

      perPage: 3,
      currentPage: 1,
      currentPageDevis: 1,
      
    };
  },

  async mounted() {
    document.title = 'Détails Client - Ediqia';
    this.userData = JSON.parse(localStorage.getItem('clientDetails'));

    

    await this.getFactures();
    await this.getArchive();
   
  },

  methods: {
    formatMoney(amount) {
      const dataToDecrypt = localStorage.getItem('entreprise_info');
         return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    },

    

    async getFactures() {
      const id = {
      client_id: this.userData.id,
    };
       try {
      await axios.post(URL.LISTE_FACTURE_CLIENT, id).then((reponse) => {
        this.listFacClient = reponse.data.list_facture_client;

        for (let index = 0; index <  this.listFacClient.length; index++) {

          const invoiceAll =  this.listFacClient[index];
          let id =  invoiceAll.id;
          let somme = 0;
          this.versementsData = invoiceAll.versements
          console.log(this.versementsData);
          for (
            let index2 = 0;
            index2 <  invoiceAll.versements.length;
            index2++
          ) {
            somme +=  invoiceAll.versements[index2].montant;
          }
          this.versements.push({
            facture: id,
            montantTotal: somme,
          });
          
          invoiceAll.paye =
            this.formatMoney(somme);
          invoiceAll.impaye =
            this.formatMoney(parseInt(invoiceAll.total_ttc) - somme)
            invoiceAll.total_ttc = this.formatMoney(invoiceAll.total_ttc)
        }
        console.log(this.listFacClient);

        //filter sur les factures donc le status est devis
        this.devisData  = this.listFacClient.filter(
          (facture) => facture.status === 'devis'
        );

        //filter sur les factures donc le status est facture
        this.inData = this.listFacClient.filter(
          (facture) => facture.status === 'facture'
        );

      });
    } catch (error) {
      console.log(error);
    }
    },


    async getArchive(){
        const id = {
      subject_id: this.userData.id,
    };
       try {
     const reponse = await axios.post(URL.CLIENT_HISTORIQUE, id)
     const historique = reponse.data.historique;
     if(reponse.status === 200 || reponse.status === 201){
       this.archiveData = historique;
     }
    }catch (error) {
      console.log(error);
    }
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD-MM-YYYY');
      }
    },

    update(){
      localStorage.setItem('place_detail_user', JSON.stringify(this.userData.localisation))
      console.log(this.userData.localisation);
      this.user.nom = this.userData.nom;
      this.user.prenoms = this.userData.prenoms;
      this.user.email = this.userData.email;
      this.user.contact = this.userData.contact;
      this.user.localisation = this.userData.localisation === null || this.userData.localisation === undefined ? '' : this.userData.localisation.formatted_address;
      this.user.type_client = this.userData.type_client === 1 ? 'Particulier' : 'Entreprise';

    },
    

    updateClient(Id) {
      const userId = Id;
      this.$swal({
        title: 'Êtes vous sûr de vouloir enregistrer',
        text: 'Les modificiations seront prises en compte',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, modifier !',
        cancelButtonText: 'Annuler',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.state.loading = true
          try {
            if (this.type_client == 'Particulier') {
              this.status_id = 1;
            } else {
              this.status_id = 2;
            }
            const data = {
              id: userId,
              nom: this.user.nom,
              prenoms: this.user.prenoms,
              email: this.user.email,
              contact: this.user.contact,
              indicateur: this.indicateur,
              type_client: this.status_id,
              localisation: JSON.parse(localStorage.getItem('place_detail_user')),
            };
            console.log(data);
            const config = {
              headers: {
                Accept: 'application/json',
              },
            };
            axios
              .post(URL.CLIENT_UPDATE, data, config)
              .then((response) => {
                if (response.data) {
                  console.log(response.data);
                  this.userData.nom = data.nom;
                  this.userData.prenoms = data.prenoms;
                  this.userData.email = data.email;
                  this.userData.contact = data.contact;
                  this.userData.localisation = data.localisation;
                  this.userData.type_client = data.type_client;
                  this.userData.indicateur =  data.indicateur;

                  toast_update(this, 'success', 'top-right', 'Clients');
                  localStorage.setItem('clientDetails', JSON.stringify(this.userData))
                  this.state.loading = false
                }
              })
              .catch((error) => {
                 this.state.loading = false
                  console.log('api error', error);
                
              });
          } catch (error) {
            console.log('trycatch error', error);
          }
        }
      });
    },
    changer(e) {
      this.indicateur = `+ ${e.dialCode}`;
      console.log(this.user.contact);
    },
  },
  setup() {
    const inData = ref([]);
    const devisData = ref([]);
    const archiveData = ref([]);
    const versementsData = ref([]);
    const state = reactive({
      loading: false
    })

  onMounted(() => {
         google.maps.event.addDomListener(window, 'load', initialize);
      });

      const initialize = () => {
         var input = document.getElementById('autocomplete_search');
         var autocomplete = new google.maps.places.Autocomplete(input);
         autocomplete.addListener('place_changed', function() {
            var place = autocomplete.getPlace();
            // place variable will have all the information you are looking for.
            $('#lat').val(place.geometry['location'].lat());
            $('#long').val(place.geometry['location'].lng());
            localStorage.setItem('place_detail_user', JSON.stringify(place));
         });
      };

    return {
      inData,
      devisData,
      avatarText,
      archiveData,
      versementsData,
      initialize,
      state
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.st {
  font-size: 10px;
}
</style>
